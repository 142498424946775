import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

import LogoTalkhub from "@/assets/images/logo-talkhub.svg";
import * as commonUtils from "@/utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "220px",
    padding: "30px 0px 16px 0px",
  },
  header: {
    width: "100%",
    alignSelf: "center",
    color: theme.palette.primary.main,
    "& .headerText": {
      fontSize: "1rem",
      fontWeight: "700",
      textAlign: "center",
    },
  },
  baseForm: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.16)",
    alignSelf: "center",
    justifySelf: "center",
    borderRadius: "10px",
    marginTop: "25px",
    padding: "20px 25px 25px",
    width: "356px",
  },
  footer: {
    width: "100%",
    fontSize: "1rem",
    marginBottom: "30px",
    alignSelf: "flex-end",
    "& p": {
      color: theme.palette.grey[1755],
    },
    "& a": {
      marginLeft: "10px",
      color: theme.palette.link.main,
    },
  },
}));

/*
  Reference:
  https://www.chromium.org/developers/design-documents/create-amazing-password-forms
*/
const AuthenticationPage = ({ children, headerText, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", outline: "none" }}
      tabIndex={0}
      {...otherProps}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        xs={4}
        style={{ minWidth: "300px" }}
      >
        <Grid container item justifyContent="center" className={classes.logo}>
          <LogoTalkhub />
        </Grid>
        <Grid container item justifyContent="center" className={classes.header}>
          <div className="headerText">{headerText}</div>
        </Grid>
        <form className={classes.baseForm}>{children}</form>
      </Grid>
      <Grid container item justifyContent="center" className={classes.footer}>
        <p>Messenger &copy; {commonUtils.getCurrentYear()}</p>
        <a
          href="https://www.talkhub.io/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>
        <a
          href="https://www.talkhub.io/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy
        </a>
      </Grid>
    </Grid>
  );
};

export default AuthenticationPage;
